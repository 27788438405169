import React from "react";

// Redux
import { useSelector } from "react-redux";

// Styled
import styled from "styled-components";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import JobCard from "../components/cards/carriers/JobCard";

// Utils
import Colors from "../utils/Colors";
import { devices } from "../utils/Responsive";
import SEO from "../utils/SEO";
import { getImage } from "../utils/Media";

export default function Carriers() {
  // Redux
  const lang = useSelector((state) => state.lang.locale);

  // Data
  const jobs = lang.carriers.jobs;

  return (
    <StyledPage>
      <SEO
        title={`${process.env.REACT_APP_NAME} - ${lang.carriers.title}`}
        description={lang.carriers.description}
        image={getImage(
          "images",

          "preview.png"
        )}
      />

      <Header />

      <Main>
        <Section>
          <SectionHeader>
            <SectionHeaderTitle>{jobs.header.title}</SectionHeaderTitle>
          </SectionHeader>
          <SectionContent>
            {jobs.content.cards.length ? (
              <Jobs>
                {jobs.content.cards.map((job, index) => {
                  return <JobCard key={index} data={job} />;
                })}
              </Jobs>
            ) : null}
          </SectionContent>
        </Section>
      </Main>

      <Footer />
    </StyledPage>
  );
}

const StyledPage = styled.div``;

const Main = styled.main``;

const Section = styled.section`
  padding: 30px 20px;
  margin: 30px 0;
`;

const SectionHeader = styled.div``;
const SectionHeaderTitle = styled.h2`
  text-align: center;
  color: ${Colors.extremeGray};
  font-weight: 700;
  margin: 0;
  transition: font-size ease-in-out 0.1s;

  @media screen and (${devices.md}) {
    font-size: 2.5em;
    transition: font-size ease-in-out 0.1s;
  }
`;

const SectionContent = styled.div`
  margin: 30px auto 0 auto;
  max-width: 1440px;
`;

const Jobs = styled.ul`
  padding: 0;
  display: grid;
  grid-gap: 30px;

  @media screen and (${devices.md}) {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }

  @media screen and (${devices.lg}) {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
`;
