import React from "react";

// Router
import { Link } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Styled
import styled from "styled-components";

// Utils
import Colors from "../utils/Colors";
import { getImage } from "../utils/Media";
import { AppRoutes } from "../utils/Router";
import { devices } from "../utils/Responsive";
import { openExternalLink } from "../utils/Link";

export default function Footer() {
  // Redux
  const lang = useSelector((state) => state.lang.locale);

  return (
    <StyledComponent>
      <Wrapper>
        <Society>
          <Link to={AppRoutes.Home}>
            <SocietyLogo
              src={getImage("images", lang.footer.logo)}
              alt={process.env.REACT_APP_NAME}
            />
          </Link>
          <SocietyInfos>
            <SocietyInfosItem>
              <SocietyInfosItemIcon
                src={getImage("images/icons", "phone.svg")}
                alt="Phone"
              />
              <SocietyInfosItemValue>
                {lang.society.infos.phone}
              </SocietyInfosItemValue>
            </SocietyInfosItem>
            <SocietyInfosItem>
              <SocietyInfosItemIcon
                src={getImage("images/icons", "mail.svg")}
                alt="Mail"
              />
              <SocietyInfosItemValue style={{ textDecoration: "underline" }}>
                {lang.society.infos.email}
              </SocietyInfosItemValue>
            </SocietyInfosItem>
          </SocietyInfos>
        </Society>
        <Banner>
          <BannerTitle>{lang.footer.banner.title}</BannerTitle>
          <BannerDescription>
            {lang.footer.banner.description}
          </BannerDescription>
          <BannerButtonLink to={AppRoutes.Contact}>
            {lang.footer.banner.button}
          </BannerButtonLink>
        </Banner>
      </Wrapper>
      <WrapperFlex>
        <Copyright>
          {lang.footer.copyright
            .replace("%app_name%", process.env.REACT_APP_NAME)
            .replace("%date_year%", new Date().getFullYear())}
        </Copyright>
        {lang.footer.socials.length ? (
          <Socials>
            {lang.footer.socials.map((social, index) => {
              return (
                <SocialsItem key={index}>
                  <SocialsItemIcon
                    src={getImage("images/icons/socials", social.icon)}
                    alt={social.name}
                  />
                  <SocialsItemLink
                    onClick={(event) => openExternalLink(event, social.link)}
                  >
                    {social.name}
                  </SocialsItemLink>
                </SocialsItem>
              );
            })}
          </Socials>
        ) : null}
      </WrapperFlex>
    </StyledComponent>
  );
}

const StyledComponent = styled.footer`
  background-color: ${Colors.primary};
  padding: 30px 30px 0 30px;
`;
const Wrapper = styled.div`
  @media screen and (${devices.lg}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;
const WrapperFlex = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (${devices.lg}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const Society = styled.div`
  width: max-content;
`;
const SocietyLogo = styled.img`
  display: block;
  width: 180px;
`;
const SocietyInfos = styled.ul`
  padding: 0;
  list-style: none;
`;
const SocietyInfosItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;
const SocietyInfosItemIcon = styled.img`
  display: block;
  width: 25px;
  height: 25px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(5deg) brightness(102%)
    contrast(102%);
`;
const SocietyInfosItemValue = styled.p`
  margin: 0 0 0 10px;
  color: white;
  font-weight: 700;
`;

const Banner = styled.div`
  margin-top: 30px;
  max-width: 425px;

  @media screen and (${devices.md}) {
    margin-top: 0;
    max-width: initial;
  }
`;
const BannerTitle = styled.h2`
  color: white;
  font-weight: 700;

  @media screen and (${devices.md}) {
    margin: 0;
  }
`;
const BannerDescription = styled.p`
  color: white;
  font-weight: 500;
`;
const BannerButtonLink = styled(Link)`
  background-color: transparent;
  color: white;
  font-weight: 500;
  font-size: inherit;
  font-family: inherit;
  border: 1px solid white;
  padding: 10px 20px;
  display: block;
  margin-top: 20px;
  width: max-content;
`;

const Socials = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 30px;
`;
const SocialsItem = styled.li`
  display: flex;
  align-items: center;
`;
const SocialsItemIcon = styled.img`
  display: block;
  width: 25px;
  height: 25px;
`;
const SocialsItemLink = styled.button`
  border: none;
  background-color: transparent;
  color: white;
  font-weight: 500;
  font-size: inherit;
  font-family: inherit;
  margin-left: 5px;
  cursor: pointer;
`;

const Copyright = styled.p`
  margin: 30px 0 0 0;
  color: white;
  font-weight: 500;
`;
