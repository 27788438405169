import React from "react";

const generateBreadcrumbs = (currentPath, routes) => {
  const breadcrumbs = [];
  const routeKeys = Object.keys(routes);

  for (let i = 0; i < routeKeys.length; i++) {
    const routeName = routeKeys[i];
    const routePath = routes[routeName];

    if (routePath.startsWith(currentPath)) {

      breadcrumbs.push({
        "@type": "ListItem",
        position: breadcrumbs.length + 1,
        name: routeName,
        url: `${process.env.REACT_APP_WEBSITE}#${routePath}`,

      });
    }
  }

  return breadcrumbs;
};

export function breadcrumbs({ currentPath, routes }) {
  const breadcrumbData = generateBreadcrumbs(currentPath, routes);

  const jsonLdData =
    currentPath === "/"
      ? {
          "@context": "https://schema.org",
          "@type": "ItemList",
          url: `${process.env.REACT_APP_WEBSITE}`,
          name: `${process.env.REACT_APP_NAME}`,
          itemListElement: breadcrumbData,
        }
      : {
          "@context": "https://schema.org",
          "@type": "ItemList",

          itemListElement: breadcrumbData,
        };

  return (
    <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
  );
}
