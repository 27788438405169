import { createSlice } from "@reduxjs/toolkit";

// Langs
import EN from "../../langs/en"

const langSlice = createSlice({
    name: "lang",
    initialState: {
        locale: JSON.parse(localStorage.getItem("lang")) || EN
    },
    reducers: {
        setLang: (state, action) => {
            state.locale = action.payload;
        }
    }
})

export const { setLang } = langSlice.actions;

export const langReducer = langSlice.reducer;