import React, { useState } from "react";

// Router
import { Navigate, useParams } from "react-router-dom";

// EmailJS
import emailjs from "@emailjs/browser";

// Redux
import { useSelector } from "react-redux";

// Styled
import styled from "styled-components";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Error from "../components/utils/Error";

// Utils
import { AppRoutes } from "../utils/Router";
import { getImage } from "../utils/Media";
import { devices } from "../utils/Responsive";
import { EmailRegEx, PhoneRegEx } from "../utils/Validation";
import Colors from "../utils/Colors";
import SEO from "../utils/SEO";

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_CARRIER_TEMPLATE_ID;

export default function CarrierDetails() {
  // Router
  const { id } = useParams();
  const parsedId = parseInt(id);

  // Redux
  const lang = useSelector((state) => state.lang.locale);

  // Datas
  const jobs = lang.carriers.jobs;
  const application = lang.carrierDetails.application;

  // Verifications
  const job = jobs.content.cards.find((job) => parsedId === job.id);

  // States
  const defaultInputs = {
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    additionalInfos: "",
    file: {},
  };
  const [inputs, setInputs] = useState(defaultInputs);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!job) {
    return <Navigate to={AppRoutes.Carriers} />;
  }

  // Methods
  const onChange = (event) => {
    const id = event.target.id;
    setInputs({
      ...inputs,
      [id]: id === "file" ? event.target.files[0] : event.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const tempErrors = {};

    setErrors({});
    setIsSubmitting(true);

    if (!inputs.firstName) {
      tempErrors.firstName =
        application.content.form.inputs.firstName.validations.required;
    }

    if (!inputs.lastName) {
      tempErrors.lastName =
        application.content.form.inputs.lastName.validations.required;
    }

    if (!inputs.email) {
      tempErrors.email =
        application.content.form.inputs.email.validations.required;
    } else if (!EmailRegEx.test(inputs.email)) {
      tempErrors.email =
        application.content.form.inputs.email.validations.pattern;
    }

    if (!inputs.phone) {
      tempErrors.phone =
        application.content.form.inputs.phone.validations.required;
    } else if (!PhoneRegEx.test(inputs.phone)) {
      tempErrors.phone =
        application.content.form.inputs.phone.validations.pattern;
    }

    if (!inputs.file || !inputs.file.name) {
      tempErrors.file =
        application.content.form.inputs.file.validations.required;
    } else if (inputs.file.size / 1024 > 1024) {
      tempErrors.file = application.content.form.inputs.file.validations.max;
    }

    setIsSubmitting(false);

    if (Object.keys(tempErrors).length) {
      setErrors(tempErrors);
      return;
    }

    await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, event.target).then(
      (result) => {
        setSuccess(application.content.form.success);
        setInputs(defaultInputs);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <StyledPage>
      <SEO
        title={`${process.env.REACT_APP_NAME} - ${lang.carrierDetails.title}`}
        description={lang.carrierDetails.description}
        image={getImage(
          "images",

          "preview.png"
        )}
      />

      <Header />

      <Main>
        <About>
          <AboutHeader>
            <AboutHeaderTitle>{job.name}</AboutHeaderTitle>
            <AboutHeaderTags>
              {job.location && (
                <AboutHeaderTag>
                  <AboutHeaderTagIcon
                    src={getImage("images/icons/carriers", "location.svg")}
                    alt={job.location}
                  />{" "}
                  {job.location}
                </AboutHeaderTag>
              )}
              {job.salary && (
                <AboutHeaderTag>
                  <AboutHeaderTagIcon
                    src={getImage("images/icons/carriers", "salary.svg")}
                    alt={job.salary}
                  />{" "}
                  {job.salary}
                </AboutHeaderTag>
              )}
              {job.remote && (
                <AboutHeaderTag>
                  <AboutHeaderTagIcon
                    src={getImage("images/icons/carriers", "remote.svg")}
                    alt={job.remote}
                  />{" "}
                  {job.remote}
                </AboutHeaderTag>
              )}
              {job.experience && (
                <AboutHeaderTag>
                  <AboutHeaderTagIcon
                    src={getImage("images/icons/carriers", "experience.svg")}
                    alt={job.experience}
                  />{" "}
                  {job.experience}
                </AboutHeaderTag>
              )}
            </AboutHeaderTags>
          </AboutHeader>
          <AboutContent>
            <AboutSection>
              <AboutSectionTitle>
                {application.content.left.job.title}
              </AboutSectionTitle>
              {job.jobDescription.texts.length ? (
                <AboutSectionDescription>
                  {job.jobDescription.texts.map((text, index) => {
                    return (
                      <AboutSectionDescriptionText key={index}>
                        {text}
                      </AboutSectionDescriptionText>
                    );
                  })}
                </AboutSectionDescription>
              ) : null}
              {job.jobDescription.list.length ? (
                <AboutSectionList>
                  {job.jobDescription.list.map((item, index) => {
                    return (
                      <AboutSectionListItem key={index}>
                        {item}
                      </AboutSectionListItem>
                    );
                  })}
                </AboutSectionList>
              ) : null}
            </AboutSection>
            <AboutSection>
              <AboutSectionTitle>
                {application.content.left.profile.title}
              </AboutSectionTitle>
              {job.profileRequired.texts.length ? (
                <AboutSectionDescription>
                  {job.profileRequired.texts.map((text, index) => {
                    return (
                      <AboutSectionDescriptionText key={index}>
                        {text}
                      </AboutSectionDescriptionText>
                    );
                  })}
                </AboutSectionDescription>
              ) : null}
              {job.profileRequired.list.length ? (
                <AboutSectionList>
                  {job.profileRequired.list.map((item, index) => {
                    return (
                      <AboutSectionListItem key={index}>
                        {item}
                      </AboutSectionListItem>
                    );
                  })}
                </AboutSectionList>
              ) : null}
            </AboutSection>
          </AboutContent>
        </About>
        <ApplicationForm
          encType="multipart/form-data"
          method="post"
          onSubmit={onSubmit}
        >
          <ApplicationFormTitle>
            {application.content.form.title}
          </ApplicationFormTitle>
          {success && (
            <ApplicationFormSuccess>{success}</ApplicationFormSuccess>
          )}
          <ApplicationFormGroups>
            <ApplicationFormGroupWrapper>
              <ApplicationFormGroup>
                <ApplicationFormGroupLabel htmlFor="firstName">
                  {application.content.form.inputs.firstName.label}{" "}
                  <ApplicationFormGroupLabelSpan>
                    *
                  </ApplicationFormGroupLabelSpan>
                </ApplicationFormGroupLabel>
                <ApplicationFormGroupInput
                  id="firstName"
                  name="firstName"
                  type="text"
                  error={errors.firstName}
                  disabled={isSubmitting}
                  placeholder={
                    application.content.form.inputs.firstName.placeholder
                  }
                  value={inputs.firstName}
                  onChange={onChange}
                />
                {errors.firstName && <Error children={errors.firstName} />}
              </ApplicationFormGroup>
              <ApplicationFormGroup>
                <ApplicationFormGroupLabel htmlFor="lastName">
                  {application.content.form.inputs.lastName.label}{" "}
                  <ApplicationFormGroupLabelSpan>
                    *
                  </ApplicationFormGroupLabelSpan>
                </ApplicationFormGroupLabel>
                <ApplicationFormGroupInput
                  id="lastName"
                  name="lastName"
                  type="text"
                  error={errors.lastName}
                  disabled={isSubmitting}
                  placeholder={
                    application.content.form.inputs.lastName.placeholder
                  }
                  value={inputs.lastName}
                  onChange={onChange}
                />
                {errors.firstName && <Error children={errors.firstName} />}
              </ApplicationFormGroup>
            </ApplicationFormGroupWrapper>
            <ApplicationFormGroup>
              <ApplicationFormGroupLabel htmlFor="email">
                {application.content.form.inputs.email.label}{" "}
                <ApplicationFormGroupLabelSpan>*</ApplicationFormGroupLabelSpan>
              </ApplicationFormGroupLabel>
              <ApplicationFormGroupInput
                id="email"
                name="email"
                type="email"
                error={errors.email}
                disabled={isSubmitting}
                placeholder={application.content.form.inputs.email.placeholder}
                value={inputs.email}
                onChange={onChange}
              />
              {errors.email && <Error children={errors.email} />}
            </ApplicationFormGroup>
            <ApplicationFormGroup>
              <ApplicationFormGroupLabel htmlFor="phone">
                {application.content.form.inputs.phone.label}{" "}
                <ApplicationFormGroupLabelSpan>*</ApplicationFormGroupLabelSpan>
              </ApplicationFormGroupLabel>
              <ApplicationFormGroupInput
                id="phone"
                name="phone"
                type="tel"
                error={errors.phone}
                disabled={isSubmitting}
                placeholder={application.content.form.inputs.phone.placeholder}
                value={inputs.phone}
                onChange={onChange}
              />
              {errors.phone && <Error children={errors.phone} />}
            </ApplicationFormGroup>
            <ApplicationFormGroup>
              <ApplicationFormGroupLabel htmlFor="file">
                {application.content.form.inputs.file.label}{" "}
                <ApplicationFormGroupLabelSpan>*</ApplicationFormGroupLabelSpan>
                <ApplicationFormGroupFile error={errors.file}>
                  <ApplicationFormGroupInput
                    id="file"
                    name="file"
                    type="file"
                    accept=".pdf"
                    disabled={isSubmitting}
                    onChange={onChange}
                    hidden
                  />
                  <ApplicationFormGroupButtonIcon
                    src={getImage("images/icons", "download.svg")}
                    alt={application.content.form.inputs.file.label}
                  />{" "}
                  {application.content.form.inputs.file.title}
                </ApplicationFormGroupFile>
              </ApplicationFormGroupLabel>
              {errors.file && <Error children={errors.file} />}
            </ApplicationFormGroup>
            <ApplicationFormGroup>
              <ApplicationFormGroupLabel htmlFor="additionalInfos">
                {application.content.form.inputs.additionalInfos.label}
              </ApplicationFormGroupLabel>
              <ApplicationFormGroupTextArea
                id="additionalInfos"
                name="additionalInfos"
                cols={3}
                rows={3}
                error={errors.additionalInfos}
                disabled={isSubmitting}
                placeholder={
                  application.content.form.inputs.additionalInfos.placeholder
                }
                value={inputs.additionalInfos && inputs.additionalInfos}
                onChange={onChange}
              />
              {errors.aditionalInfos && (
                <Error children={errors.additionalInfos} />
              )}
            </ApplicationFormGroup>
            <ApplicationFormGroup>
              <ApplicationFormGroupButtonSubmit
                type="submit"
                disabled={isSubmitting}
              >
                {application.content.form.inputs.submit.title}
              </ApplicationFormGroupButtonSubmit>
            </ApplicationFormGroup>
          </ApplicationFormGroups>
        </ApplicationForm>
      </Main>

      <Footer />
    </StyledPage>
  );
}

const StyledPage = styled.div``;

const Main = styled.main`
  padding: 30px;
  display: grid;

  @media screen and (${devices.lg}) {
    max-width: 90%;
    margin: 30px auto;
    grid-gap: 60px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const About = styled.div``;
const AboutHeader = styled.div``;
const AboutHeaderTitle = styled.h1`
  margin: 0;
  color: ${Colors.extremeGray};
`;
const AboutHeaderTags = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 10px;
  align-items: center;

  @media screen and (${devices.md}) {
    grid-template-columns: repeat(4, max-content);
  }
`;
const AboutHeaderTag = styled.li`
  background-color: ${Colors.gray};
  color: ${Colors.extremeGray};
  font-weight: 600;
  font-size: 0.8em;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;
const AboutHeaderTagIcon = styled.img`
  display: block;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  filter: invert(18%) sepia(15%) saturate(1121%) hue-rotate(204deg)
    brightness(72%) contrast(91%);
`;

const AboutContent = styled.div`
  margin: 60px 0;
`;
const AboutSection = styled.div``;
const AboutSectionTitle = styled.h2`
  margin: 0;
  color: ${Colors.extremeGray};
`;
const AboutSectionDescription = styled.div``;
const AboutSectionDescriptionText = styled.p`
  color: ${Colors.extremeGray};
`;
const AboutSectionList = styled.ul`
  padding: 0 20px;
`;
const AboutSectionListItem = styled.li`
  color: ${Colors.extremeGray};
`;

const ApplicationForm = styled.form``;
const ApplicationFormTitle = styled.h3`
  margin: 0;
  color: ${Colors.extremeGray};
`;
const ApplicationFormSuccess = styled.p`
  color: ${Colors.green};
`;
const ApplicationFormGroups = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
`;
const ApplicationFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const ApplicationFormGroupWrapper = styled.div`
  display: grid;
  grid-gap: 20px;

  @media screen and (${devices.lg}) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
`;
const ApplicationFormGroupLabel = styled.label`
  color: ${Colors.extremeGray};
  font-weight: 500;
  margin-bottom: 10px;
`;
const ApplicationFormGroupLabelSpan = styled.span`
  color: ${Colors.red};
`;
const ApplicationFormGroupFile = styled.div`
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${Colors.primary};
  color: ${Colors.primary};
  font-size: 0.8em;
  font-weight: 600;
  font-family: inherit;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;
  transition: font-size ease-in-out 0.1s;
  margin-top: 10px;

  ${(props) => {
    if (props.error) {
      return `
        border: 1px solid ${Colors.red};
        color: ${Colors.red};
        `;
    } else {
      return `
            &:hover {
                transition: .2s;
                background-color: ${Colors.primary};
                color: white;
            }
            `;
    }
  }}

  @media screen and (${devices.lg}) {
    font-size: 1em;
    transition: font-size ease-in-out 0.1s;
  }
`;
const ApplicationFormGroupInput = styled.input`
  border-radius: 10px;
  padding: 20px 30px;
  font-family: inherit;
  background-color: ${Colors.gray};
  border: 1px solid transparent;
  transition: 0.3s;
  width: 100%;
  outline: none;

  ${(props) => {
    if (props.error) {
      return `
    border: 1px solid ${Colors.red};
    `;
    } else {
      return `
    &:focus {
      transition: .2s;
      border: 1px solid ${Colors.primary};
    }
    `;
    }
  }}
`;
const ApplicationFormGroupTextArea = styled.textarea`
  border-radius: 10px;
  padding: 20px 30px;
  font-family: inherit;
  background-color: ${Colors.gray};
  border: 1px solid transparent;
  transition: 0.3s;
  width: 100%;
  min-height: 150px;
  resize: vertical;
  outline: none;

  ${(props) => {
    if (props.error) {
      return `
  border: 1px solid ${Colors.red};
  `;
    } else {
      return `
  &:focus {
    transition: .2s;
    border: 1px solid ${Colors.primary};
  }
  `;
    }
  }}
`;
const ApplicationFormGroupButtonIcon = styled.img`
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  filter: invert(10%) sepia(100%) saturate(6909%) hue-rotate(233deg)
    brightness(93%) contrast(90%);

  ${ApplicationFormGroupFile}:hover & {
    filter: invert(100%) sepia(8%) saturate(7424%) hue-rotate(308deg)
      brightness(116%) contrast(124%);
  }

  ${ApplicationFormGroupFile}[error] & {
    filter: invert(30%) sepia(60%) saturate(2724%) hue-rotate(341deg)
      brightness(82%) contrast(108%);
  }
`;
const ApplicationFormGroupButtonSubmit = styled.button`
  background-color: ${Colors.primary};
  border-radius: 5px;
  border: none;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  padding: 15px;
  cursor: pointer;
  transition: 0.2s;

  ${(props) => {
    if (props.disabled) {
      return `
    background-color: ${Colors.gray};
    `;
    } else {
      return `
      &:hover {
        transition: .2s;
        -moz-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
          -webkit-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
      }
    `;
    }
  }}
`;
