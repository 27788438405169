import React, { useState } from 'react'

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setLang } from "../../storage/reducers/Lang"

// Styled
import styled from "styled-components";

// Langs
import EN from '../../langs/en';
import FR from "../../langs/fr";

// Utils
import { getImage } from "../../utils/Media"
import Colors from "../../utils/Colors"

export default function Lang({ data }) {
    // Redux
    const lang = useSelector((state) => state.lang.locale);
    const dispatch = useDispatch();

    // States
    const [isOpen, setIsOpen] = useState(false);

    // Methods
    const onToggle = (event) => {
        setIsOpen(!isOpen);
    }

    const onChangeLang = (selectedLang) => {
        localStorage.setItem("lang", JSON.stringify(selectedLang));
        dispatch(setLang(selectedLang));
    }

    return (
        <StyledComponent onClick={onToggle}>
            <Icon src={getImage("images/icons/langs", lang.name === "EN" ? `us_flag.png` : "french_flag.png")} alt={lang.name === "EN" ? "English" : "Français"} />
            <Langs open={isOpen}>
                {lang.name === "EN" && <LangItem onClick={() => onChangeLang(FR)}>
                    <LangItemIcon src={getImage("images/icons/langs", "french_flag.png")} alt="Français" />
                </LangItem>}
                {lang.name === "FR" &&
                    <LangItem onClick={() => onChangeLang(EN)}>
                        <LangItemIcon src={getImage("images/icons/langs", "us_flag.png")} alt="English" />
                    </LangItem>
                }
            </Langs>
        </StyledComponent>
    )
}

const StyledComponent = styled.button`
position: fixed;
bottom: 20px;
left: 20px;
width: 50px;
height: 50px;
background-color: ${Colors.primary};
border-radius: 100px;
border: none;
padding: 10px;
box-shadow: 10px 10px 60px rgb(0 0 0 / 50%);
display: flex;
flex-direction: reverse-column;
align-items: center;
justify-content: center;
cursor: pointer;
transition: .2s;

&:hover {
    transition: .2s;
    transform: scale(1.05);
}
`;

const Icon = styled.img`
display: block;
max-width: 100%;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
width: 30px;
height: 30px;
object-fit: contain;
`;

const Langs = styled.ul`
  list-style: none;
  -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  -ms-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${Colors.primary};
  max-height: 0;
  overflow: hidden;
  border-radius: 20px;
  padding: 20px 10px;
  opacity: 0;
  visibility: hidden;
  z-index: -999;

  ${props => {
        if (props.open) {
            return `
        max-height: 100px;
        opacity: 1;
        visibility: visible;
      `;
        }
    }}
`;

const LangItem = styled.li`
width: 30px;
height: 30px;
`;
const LangItemIcon = styled.img`
display: block;
max-width: 100%;
`;