import React, { useState } from "react";

// Router
import { NavLink } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Styled
import { styled } from "styled-components";

// Utils
import Colors from "../utils/Colors";
import { getImage } from "../utils/Media";
import { AppRoutes } from "../utils/Router";
import { devices } from "../utils/Responsive";
import env from "../env";

// Variables
const navbarSpeed = ".5s";

const prodMode = process.env.REACT_APP_ENV === env.prod;

export default function Navbar() {
  // Redux
  const lang = useSelector((state) => state.lang.locale);

  // States
  const [isOpen, setIsOpen] = useState(false);

  // Methods
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledComponent>
      <Wrapper>
        <NavLink to={AppRoutes.Home}>
          <WrapperLogo
            src={getImage(
              "images",
              prodMode
                ? "logo-blue-2.png"
                : "move2cloud-logos/Color-logo-with-background.png"
            )}
            alt={process.env.REACT_APP_NAME}
          />
        </NavLink>
        <WrapperToggle open={isOpen} onClick={onToggle} />
      </Wrapper>
      <Menu>
        <MenuItems open={isOpen}>
          <MenuItem>
            <MenuItemLink
              to={AppRoutes.Home}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              {lang.navbar.links.home}
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to={AppRoutes.Services}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              {lang.navbar.links.services}
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to={AppRoutes.Carriers}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              {lang.navbar.links.carriers}
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to={AppRoutes.About}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              {lang.navbar.links.aboutUs}
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="https://ui.boondmanager.com/?customerCode=move2cloud"
              target="_blank"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              {lang.navbar.links.boond}
            </MenuItemLink>
          </MenuItem>
        </MenuItems>
        <MenuButtonLink to={AppRoutes.Contact}>
          {lang.navbar.contact}
        </MenuButtonLink>
      </Menu>
    </StyledComponent>
  );
}

const StyledComponent = styled.nav`
  border-bottom: 1px solid ${Colors.lightGray};
  padding: 20px 20px 0 20px;

  @media screen and (${devices.lg}) {
    display: flex;
    align-items: center;
    padding: 20px;
  }
`;

const Menu = styled.div`
  @media screen and (${devices.lg}) {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;
const MenuItems = styled.ul`
  padding: 0;
  list-style: none;
  -webkit-transition: max-height ${navbarSpeed} ease-in;
  -moz-transition: max-height ${navbarSpeed} ease-in;
  -ms-transition: max-height ${navbarSpeed} ease-in;
  -o-transition: max-height ${navbarSpeed} ease-in;
  transition: max-height ${navbarSpeed} ease-in;
  overflow: hidden;
  max-height: 0;

  @media screen and (${devices.lg}) {
    max-height: initial;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  ${(props) => {
    if (props.open) {
      return `
      max-height: 200px;
    `;
    }
  }}
`;
const MenuItem = styled.li`
  margin-bottom: 20px;

  @media screen and (${devices.lg}) {
    margin: 0 20px;
  }
`;
const MenuItemLink = styled(NavLink)`
  color: ${Colors.primary};
  text-decoration: none;

  &.active {
    border-bottom: 1px solid ${Colors.primary};
  }
`;
const MenuButtonLink = styled(NavLink)`
  color: white;
  background-color: ${Colors.primary};
  border-radius: 10px;
  padding: 10px 20px;
  display: block;
  transition: 0.2s;
  font-weight: 500;
  margin-bottom: 20px;

  &:hover {
    transition: 0.2s;
    -moz-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
    -webkit-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
  }

  @media screen and (${devices.lg}) {
    margin-left: auto;
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperLogo = styled.img`
  display: block;
  width: 200px;
`;
const WrapperToggle = styled.div`
  width: 20px;
  height: 2px;
  background-color: ${Colors.primary};
  position: relative;
  -webkit-transition: -webkit-transform 0.1s ease-in;
  -ms-transition: -ms-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  cursor: pointer;

  @media screen and (${devices.lg}) {
    display: none;
  }

  ${(props) => {
    if (props.open) {
      return `
            background-color: transparent;

        &::before {
            content: ""; 
            top: 0 !important;
            transform: rotate(45deg);
            background-color: transparent;
            -webkit-transition: inherit;
            -ms-transition: inherit;
            transition: inherit;   
        }

        &::after {
            content: "";
            top: 0 !important;
            transform: rotate(-45deg);
            -webkit-transition: inherit;
            -ms-transition: inherit;
            transition: inherit;
        }
        `;
    }
  }}

  &::before {
    content: "";
    position: absolute;
    top: -7px;
    width: inherit;
    height: inherit;
    background-color: ${Colors.primary};
    -webkit-transition: inherit;
    -ms-transition: inherit;
    transition: inherit;
  }

  &::after {
    content: "";
    position: absolute;
    top: 7px;
    width: inherit;
    height: inherit;
    background-color: ${Colors.primary};
    -webkit-transition: inherit;
    -ms-transition: inherit;
    transition: inherit;
  }
`;
