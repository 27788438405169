import React from 'react';

// Styled
import styled from "styled-components"

// Utils
import { openExternalLink } from '../../../utils/Link';
import { getImage } from '../../../utils/Media';

export default function TeamCard({ data }) {
    return (
        <StyledComponent>
            <Avatar src={getImage("images/avatars", data.avatar)} alt={data.fullName} />
            {data.fullName && <FullName>{data.fullName}</FullName>}
            {data.job && <Job>{data.job}</Job>}
            <Socials>
                {data.linkedin && <SocialItem>
                    <SocialItemLink onClick={(event) => openExternalLink(event, data.linkedin)}>
                        <SocialItemLinkIcon src={getImage("images/icons/socials", "linkedin.svg")} alt="LinkedIn" />
                    </SocialItemLink>
                </SocialItem>}
            </Socials>
        </StyledComponent>
    )
}

const StyledComponent = styled.li`
display: flex;
flex-direction: column;
align-items: center;
`;
const Avatar = styled.img`
max-width: 100%;
width: 160px;
height: 160px;
border-radius: 100px;
object-fit: cover;
`;
const FullName = styled.h3`
color: white;
margin: 20px 0 0 0;
`;
const Job = styled.p`
margin: 0;
color: white;
font-weight: 600;
`;

const Socials = styled.ul`
list-style: none;
padding: 0;
margin-top: 10px;
`;
const SocialItem = styled.li``;
const SocialItemLink = styled.div`
cursor: pointer;
`;
const SocialItemLinkIcon = styled.img`
display: block;
width: 24px;
height: 24px;
`;