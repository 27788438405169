import React from 'react'

// Router
import { Link, useLocation } from "react-router-dom"
import { AppRoutes } from "../utils/Router"

// Redux
import { useSelector } from "react-redux"

// Styled
import { styled } from 'styled-components'

// Components
import Navbar from "./Navbar"

// Utils
import { getImage } from "../utils/Media"
import { devices } from "../utils/Responsive"

export default function Header() {
    // Router
    const location = useLocation();

    // Redux
    const lang = useSelector((state) => state.lang.locale);

    return (
        <StyledComponent>
            <Navbar />

            {location.pathname === AppRoutes.Home ?
                <Content background={lang.home.header.content.image}>
                    <ContentInfos>
                        <ContentInfoTitle>{lang.home.header.content.infos.title}</ContentInfoTitle>
                        <ContentInfoButtonLink to={AppRoutes.About}>{lang.home.header.content.infos.button}</ContentInfoButtonLink>
                    </ContentInfos>
                </Content> :
                location.pathname === AppRoutes.Carriers ?
                    <Banner>
                        <BannerContent>
                            <BannerContentTitle>{lang.carriers.header.title}</BannerContentTitle>
                            <BannerContentDescription>{lang.carriers.header.description}</BannerContentDescription>
                        </BannerContent>
                    </Banner> : null}
        </StyledComponent>
    )
}

const StyledComponent = styled.header`
`;
const Content = styled.div`
background-image: linear-gradient(to bottom, rgba(13,65,225, 0.5), rgba(13,65,225, 0.5)), url(${props => getImage("images", props.background)});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
padding: 30px;
transition: padding ease-in-out .1s;

@media screen and (${devices.lg}) {
    padding: 300px 30px;
    transition: padding ease-in-out .1s;
}
`;
const ContentInfos = styled.div`
max-width: 90%;
margin: 0 auto;
`;
const ContentInfoTitle = styled.h1`
margin: 0;
color: white;
max-width: 768px;
`;
const ContentInfoButtonLink = styled(Link)`
border: 1px solid white;
color: white;
padding: 10px 20px;
display: block;
width: max-content;
margin-top: 30px;
border-radius: 5px;
transition: .2s;

&:hover {
    transform: scale(1.05);
    transition: .2s;
}
`;

const Banner = styled.div`
background-image: linear-gradient(to bottom, rgba(13,65,225, 0.9), rgba(13,65,225, 0.9)), url(${getImage("images", "carriers-header.jpg")});
background-position: center;
background-repeat: no-repeat;
display: flex;
align-items: center;
`;
const BannerContent = styled.div`
max-width: 1024px;
margin: 0 auto;
padding: 60px 30px;
`;
const BannerContentTitle = styled.h1`
text-align: center;
color: white;
`;
const BannerContentDescription = styled.h3`
color: white;
text-align: center;
font-weight: 500;
`;