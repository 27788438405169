import React from "react";

// Router
import {
  HashRouter as Router,

  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AppRoutes } from "./utils/Router";

// EmailJS
import { init } from "@emailjs/browser";

// Pages
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Carriers from "./pages/Carriers";
import CarrierDetails from "./pages/CarrierDetails";

// Components
import Lang from "./components/utils/Lang";
import Scroll from "./components/utils/Scroll";

const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export default function App() {
  // EmailJS
  init(PUBLIC_KEY);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path={`${AppRoutes.CarrierDetails}/:id`}
          element={<CarrierDetails />}
        />
        <Route exact path={AppRoutes.Carriers} element={<Carriers />} />
        <Route exact path={AppRoutes.About} element={<About />} />
        <Route exact path={AppRoutes.Contact} element={<Contact />} />
        <Route exact path={AppRoutes.Services} element={<Services />} />
        <Route exact path={AppRoutes.Home} element={<Home />} />
        <Route path="*" element={<Navigate to={AppRoutes.Home} />} />
      </Routes>

      <Lang />
      <Scroll />
    </Router>
  );
}
