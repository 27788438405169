import React from "react";

// Router
import { Link } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Styled
import styled from "styled-components";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import TeamCard from "../components/cards/about/TeamCard";
import CustomerCard from "../components/cards/about/CustomerCard";

// Utils
import { AppRoutes } from "../utils/Router";
import { devices } from "../utils/Responsive";
import Colors from "../utils/Colors";
import SEO from "../utils/SEO";
import { getImage } from "../utils/Media";

export default function About() {
  // Redux
  const lang = useSelector((state) => state.lang.locale);

  // Data
  const team = lang.aboutUs.team;
  const customers = lang.aboutUs.customers;

  return (
    <StyledPage>
      <SEO
        title={`${process.env.REACT_APP_NAME} - ${lang.aboutUs.title}`}
        description={lang.aboutUs.description}
        image={getImage(
          "images",

          "preview.png"
        )}
      />

      <Header />

      <Main>
        <Section style={{ backgroundColor: Colors.primary }}>
          <SectionHeader>
            <SectionHeaderTitle style={{ color: "white" }}>
              {lang.aboutUs.team.header.title}
            </SectionHeaderTitle>
          </SectionHeader>
          <SectionContent>
            {team.content.cards.length ? (
              <TeamList>
                {team.content.cards.map((team, index) => {
                  return <TeamCard key={index} data={team} />;
                })}
              </TeamList>
            ) : null}
            <ButtonLink to={AppRoutes.Carriers}>
              {team.content.button}
            </ButtonLink>
          </SectionContent>
        </Section>

        {customers.content.cards.length ? (
          <Section style={{ backgroundColor: Colors.lightGray }}>
            <SectionHeader>
              <SectionHeaderTitle>{customers.header.title}</SectionHeaderTitle>
            </SectionHeader>
            <SectionContent>
              <Customers>
                {customers.content.cards.map((customer, index) => {
                  return <CustomerCard key={index} data={customer} />;
                })}
              </Customers>
            </SectionContent>
          </Section>
        ) : null}
      </Main>

      <Footer />
    </StyledPage>
  );
}

const StyledPage = styled.div``;

const Main = styled.main``;

const Section = styled.section`
  padding: 30px 0;
`;

const SectionHeader = styled.div``;
const SectionHeaderTitle = styled.h1`
  text-align: center;
  color: ${Colors.extremeGray};
  font-weight: 700;
  font-size: 2em;
  margin: 0;
  transition: font-size ease-in-out 0.1s;

  @media screen and (${devices.md}) {
    font-size: 2.5em;
    transition: font-size ease-in-out 0.1s;
  }
`;

const SectionContent = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 30px 20px;
`;

const TeamList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 60px 0;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 1024px;
  margin: 0 auto;

  @media screen and (${devices.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Customers = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-content: center;

  @media screen and (${devices.md}) {
    grid-template-columns: repeat(3, 150px);
  }
  @media screen and (${devices.lg}) {
    grid-template-columns: repeat(5, 150px);
  }
`;

const ButtonLink = styled(Link)`
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 20px;
  display: block;
  width: max-content;
  margin: 60px auto 0 auto;
  transition: 0.2s;
  font-weight: 600;

  &:hover {
    transition: 0.2s;
    transform: scale(1.05);
  }
`;
