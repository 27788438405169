import React from "react";

// Styled
import styled from "styled-components";

// Utils
import Colors from "../../utils/Colors";

export default function Error({ children }) {
  return <StyledComponent>{children}</StyledComponent>;
}

const StyledComponent = styled.p`
color: ${Colors.red};
margin: 10px 0 0 0;
`;