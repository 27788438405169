import { Helmet } from "react-helmet-async";
import { breadcrumbs } from "./breadcrumbs";
import { IndexedRoutes } from "./Router";

import env from "../env";

export default function SEO({ title, description, image }) {
  const location = window.location;


  const currentPath = location.hash.replace(/^#/, "");
  console.log(currentPath);

  const prodMode = process.env.REACT_APP_ENV === env.prod;
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>

      {!prodMode && <meta name="robots" content="noindex, nofollow" />}
      {prodMode && (
        <>
          {/* General Meta Tags */}
          <meta name="author" content={`${process.env.REACT_APP_NAME}`} />

          <link rel="canonical" href={location.href} />
          {/* JSON-LD */}
          {(Object.values(IndexedRoutes).includes(currentPath) ||
            currentPath === "") &&
            breadcrumbs({
              currentPath,
              routes: IndexedRoutes,
            })}

        </>
      )}
    </Helmet>
  );
}
