import React from 'react'

// Router
import { Link } from 'react-router-dom';

// Redux
import { useSelector } from "react-redux";

// Styled
import styled from "styled-components"

// Utils
import { AppRoutes } from "../../../utils/Router";
import Colors from "../../../utils/Colors"
import { getImage } from '../../../utils/Media';

export default function JobCard({ data }) {
    // Redux
    const lang = useSelector((state) => state.lang.locale);

    return (
        <StyledComponent to={`${AppRoutes.CarrierDetails}/${data.id}`}>
            <Header>
                <HeaderTitle>{data.name}</HeaderTitle>
                <HeaderLocation>{data.location}</HeaderLocation>
            </Header>
            <Content>
                {data.description &&
                    <ContentDescription>
                        {data.shortDescription}
                    </ContentDescription>}
            </Content>
            <Footer>
                <FooterButton>{lang.carriers.jobs.content.apply}
                    <FooterButtonIcon src={getImage("images/icons", "right-arrow.svg")} alt={lang.carriers.jobs.content.apply} /></FooterButton>
            </Footer>
        </StyledComponent>
    )
}

const StyledComponent = styled(Link)`
background-color: white;
border-radius: 10px;
box-shadow: 10px 10px 30px rgb(0 0 0 / 7%);
display: flex;
flex-direction: column;
justify-content: space-between;
cursor: pointer;
transition: .2s;

&:hover {
    transition: .2s;
    box-shadow: 10px 10px 30px rgb(0 0 0 / 15%);
}
`;

const Header = styled.div`
padding: 20px 20px 0 20px;
`;
const HeaderTitle = styled.h3`
margin: 0;
color: ${Colors.extremeGray};
`;
const HeaderLocation = styled.p`
color: gray;
margin: 0;
`;

const Content = styled.div`
padding: 0 20px 20px 20px;
`;
const ContentDescription = styled.p`
color: ${Colors.extremeGray};
`;

const Footer = styled.div`

`;
const FooterButton = styled.button`
display: flex;
align-items: center;
justify-content: space-between;
color: white;
font-weight: 600;
font-size: inherit;
font-family: inherit;
width: 100%;
background-color: ${Colors.primary};
padding: 10px 20px;
border: none;
border-radius: 0 0 10px 10px;
transition: .2s;
cursor: pointer;

${StyledComponent}:hover &, 
&:hover {
    transition: .2s;
    -moz-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
      -webkit-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.07);
  }
`;
const FooterButtonIcon = styled.img`
width: 22px;
height: 22px;
display: block;
`;
