import { configureStore } from "@reduxjs/toolkit";

// Reducers
import { langReducer } from "./reducers/Lang"

const store = configureStore({
    reducer: {
        lang: langReducer
    }
})

export default store;